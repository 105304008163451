<template>
  <main-frame>
    <div class="error-page error403">
      <a-result
        status="403"
        :title="`对不起，您没有访问当前页面的权限，正在跳转...${count}s`"
      >
        <template #extra>
          <a-button type="primary" @click="$router.replace(indexPath)">点击返回首页</a-button>
        </template>
      </a-result>
    </div>
  </main-frame>
</template>

<script>
import backToIndexMixin from './backToIndex.mixin'

export default {
  mixins: [backToIndexMixin],
}
</script>
